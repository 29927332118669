import {AbstractControl, Validators} from '@angular/forms';
import {EstadoSolicitud} from './pipes/estado-solicitud.pipe';
import {TiposCampos, TiposMiembro} from './types';
import {Cliente} from './entities/solicitudes';

export const avoidTypeText = (control: AbstractControl) =>
  control.valueChanges.subscribe(value => {
    if (value && isNaN(Number(value))) {
      control.setValue(value.substring(0, value.length - 1));
    }
  });

export const arrayBufferToBase64 = (buffer: Iterable<number>): any => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return binary;
};

export const blobToBase64 = (blob: Blob): Promise<any> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const MOTIVOS_MORA = [
  {
    id: 1,
    motivo: 'Ventas bajas'
  },
  {
    id: 2,
    motivo: 'Calamidad familiar'
  },
  {
    id: 3,
    motivo: 'Ilocalizado'
  },
  {
    id: 4,
    motivo: 'Sin voluntad de pago'
  },
  {
    id: 5,
    motivo: 'Sobreendeudamiento'
  },
  {
    id: 6,
    motivo: 'Cambio de actividad económica'
  },
  {
    id: 7,
    motivo: 'Inconformidad condiciones de crédito'
  }
];

export const RANGOS_CARTERA = [
  {id: 1, desde: 1, hasta: 7, nombre: 'Acuerdo verbal', crearAcuerdo: false},
  {id: 2, desde: 8, hasta: 19, nombre: 'Primera notificación', crearAcuerdo: true},
  {id: 3, desde: 20, hasta: 34, nombre: 'Segunda notificación', crearAcuerdo: true},
  {id: 4, desde: 35, hasta: 120, nombre: 'Tercera notificación', crearAcuerdo: true},
];

export const STORAGE_KEYS = {
  USER: 'user',
  TOKEN: 'access_token',
  SUCURSALES: 'sucursales',
  ROLES: 'roles',
  PERFILES: 'perfiles',
  CONFIG: 'config',
  CONFIG2: 'config2',
  PERMISOS: 'permisos',
  COMITE: 'comite',
  LISTADOTASAS: 'listadoTasas',
  LISTADOCOMISIONES: 'listadoComisiones'
};

export type FORM_MODE = 'editar' | 'crear';

export const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  EMAIL_LIST: /^([\w+-.%]+@[\w-.]+\.[A-z]{2,4},?)+$/,
  NUMERIC: /^[0-9]+$/,
  CELLPHONE: /^(\+)?[0-9]+( [0-9]+)*$/,
  CURRENCY: /^[0-9,.]+$/,
  ALPHANUMERIC: /^[ 0-9A-z]+$/,
  ALPHABETICAL: /^([ \u00c0-\u01ffa-zA-Z'\-])+$/,
  IDENTIFICATION_NUMBER: /^\d*(\.\d+)?$/

};

export const CODIGOS_RESP = [
  '02DATPP200-S00001', // plazos/buscar
  '02DATPP200-S00002', // plazos/buscar

  '02METBU200-S00001', // metas/buscar
  '02METBU200-S00002', // metas/buscar

  '02PERBU200-S00001', // perfiles/buscar
  '02PERBU200-S00002', // perfiles/buscar

  '02REFBU200-S00001', // referencias/buscar
  '02REFBU200-S00002', // referencias/buscar

  '02ROLBU200-S00001', // roles/buscar
  '02ROLBU200-S00002', // roles/buscar

  '02SOLBUASE200-S00001', // solicitudes/buscar/asesor
  '02SOLBUASE200-S00002', // solicitudes/buscar/asesor
  '02SOLBUID200-S00001', // solicitudes/buscar/id
  '02SOLBUID200-S00002', // solicitudes/buscar/id
  '02SOLBUNC200-S00001', // solicitudes/buscar/nombreCedula
  '02SOLBUNC200-S00002', // solicitudes/buscar/nombreCedula,
  '02SOLBUEST200-S00001', // solicitudes/buscar/estado
  '02SOLBUEST200-S00002', // solicitudes/buscar/estado
  '200-S05001', // solicitudes/contar/por/estado
  '200-S05002', // solicitudes/contar/por/estado
  '02SOLOBID200-S00001', // solicitudes/buscar/id
  '02SOLOBID200-S00002', // solicitudes/buscar/id

  '02SUCBUS200-S00001', // sucursales/buscar
  '02SUCBUS200-S00002', // sucursales/buscar

  '02USUBUS200-S00001', // usuarios/buscar
  '02USUBUS200-S00002', // usuarios/buscar

  '02USUBUSSUCASE200-S00001', // asesores/buscar
  '02UBUSSUCASE200-S00002', // asesores/buscar
  '02USUBUSSUC200-S00001', // asesores/buscarPorSucursal
  '02USUBUSSUC200-S00002', // asesores/buscarPorSucursal

  '40-AS001310-200', //Buscar tranferencias

];

export const EstadoOTP = {
  APROBADO: '1',
  NO_APROBADO: '2',
  NO_APROBADO_POR_RECONOCER: '3',
  SIN_RESPUESTA: '4',
  ERROR_VALIDACION: '5',
  EXPIRADO: '6',
  TRANSACCION_INVALIDA_INTENTO_ADICIONAL: '7',
  TRANSACCION_INVALIDA_VERIFICACION_ADICIONAL: '8',
  ERROR_GENERANDO_CODIGO: '99',
};

// TODO: implementar campos que estan en excel y serán editables
export const CamposEditables = {
  fechaNacimiento: {
    ruta: 'datosPersonalesCliente.fechaNacimiento',
    label: 'Fecha de nacimiento',
    tipo: TiposCampos.date,
    validadores: [Validators.required],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  genero: {
    ruta: 'datosPersonalesCliente.genero',
    label: 'Género',
    tipo: TiposCampos.select,
    validadores: [Validators.required],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado),
    opciones: {
      etiqueta: 'genero',
      listado: [
        {id: 1, genero: 'Masculino'},
        {id: 2, genero: 'Femenino'}
      ]
    }
  },
  nivelEducativo: {
    ruta: 'datosPersonalesCliente.nivelEducativo',
    label: 'Nivel educativo',
    tipo: TiposCampos.select,
    validadores: [Validators.required],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado),
    opciones: {
      etiqueta: 'nivel',
      listado:  [
        {id: 1, nivel: 'Ninguno'},
        {id: 2, nivel: 'Primaria'},
        {id: 3, nivel: 'Secundaria'},
        {id: 4, nivel: 'Universitario'}
      ]
    }
  },
  ciudadNacimiento: {
    ruta: 'datosIdentificacionCliente.ciudadNacimiento.departamento',
    label: 'Lugar de nacimiento',
    tipo: TiposCampos.text,
    validaciones: {},
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  numeroIdentificacionConyuge: {
    ruta: 'datosConyugue.numeroIdentificacion',
    label: 'Identificación',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.IDENTIFICATION_NUMBER.test(control.value) ?
          { pattern: 'Por favor ingresa un número de identificación válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  fechaExpedicionDocumento: {
    ruta: 'datosIdentificacionCliente.fechaExpedicion',
    label: 'Fecha de expedición',
    tipo: TiposCampos.date,
    validadores: [Validators.required],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  ciudadExpedicion: {
    ruta: 'datosIdentificacionCliente.ciudadExpedicion.municipio',
    label: 'Lugar de expedición',
    tipo: TiposCampos.text,
    validaciones: {},
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  nombresConyuge: {
    ruta: 'datosConyugue.nombres',
    label: 'Nombre del cónyuge',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  apellidosConyuge: {
    ruta: 'datosConyugue.apellidos',
    label: 'Apellido del cónyuge',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  indicaciones: {
    ruta: 'datosContactoCliente.indicaciones',
    label: 'Indicaciones para llegar',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  estrato: {
    ruta: 'datosContactoCliente.estrato',
    label: 'Estrato',
    tipo: TiposCampos.select,
    validadores: [Validators.required],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado),
    opciones: {
      etiqueta: 'estrato',
      listado: [
        {id : 1, estrato : 1},
        {id : 2, estrato : 2},
        {id : 3, estrato : 3},
        {id : 4, estrato : 4},
        {id : 5, estrato : 5},
        {id : 6, estrato : 6}
      ]
    }
  },
  telefonoComercial: {
    ruta: 'datosComerciales.telefonoComercial',
    label: 'Teléfono',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  nombreArrendador: {
    ruta: 'referenciasCliente.nombreArrendador',
    label: 'Nombre de referecia',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  personasACargo: {
    ruta: 'datosPersonalesCliente.personasACargo',
    tipo: TiposCampos.number,
    validaciones: {},
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  numeroHijosMenores: {
    ruta: 'datosPersonalesCliente.numeroHijosMenores',
    label: 'Hijos menores de edad',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(2),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  tiempoEnVivienda: {
    ruta: 'datosPersonalesCliente.tiempoEnVivienda',
    label: 'Tiempo en la vivienda',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },

  conyugeTelefono: {
    ruta: 'datosConyugue.telefono',
    label: 'Teléfono',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  telefono: {
    ruta: 'datosContactoCliente.telefono',
    label: 'Teléfono',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  telefono1: {
    ruta: 'datosContactoCliente.telefono1',
    label: 'Teléfono 2',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  email: {
    ruta: 'datosContactoCliente.email',
    label: 'Email',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.EMAIL.test(control.value) ? { pattern: 'Por favor ingresa una dirección de email valida. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  direccionCliente: {
    ruta: 'datosContactoCliente.direccion',
    label: 'Dirección',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHANUMERIC.test(control.value) ? { pattern: 'Por favor ingresa una dirección valida. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  barrio: {
    ruta: 'datosContactoCliente.barrio',
    label: 'Barrio',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  telefonoArrendador: {
    ruta: 'referenciasCliente.telefonoArrendador',
    label: 'Teléfono de referencia',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  nombreArrendadorLocal: {
    ruta: 'referenciasCliente.nombreArrendadorLocal',
    label: 'Nombre de referecia',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  telefonoArrendadorLocal: {
    ruta: 'referenciasCliente.telefonoArrendadorLocal',
    label: 'Teléfono de referencia',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  direccion: {
    ruta: 'datosComerciales.direccion',
    label: 'Dirección de la microempresa',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  tiempoExpDesarrollandoActividad: {
    ruta: 'datosComerciales.tiempoExpDesarrollandoActividad',
    label: 'Tiempo operando',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  tiempoEnLugarActual: {
    ruta: 'datosComerciales.tiempoEnLugarActual',
    label: 'Tiempo en el lugar actual',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(3),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  salariosYManoObra: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.salariosYManoObra',
    label: 'Salarios y mano de obra',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  transporte: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.transporte',
    label: 'Transporte',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  arriendo: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.arriendo',
    label: 'Arriendo',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  servicios: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.servicios',
    label: 'Servicios',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  cuotasDeCredito: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.cuotasDeCredito',
    label: 'Cuotas de créditos (terceros)',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  otrosGastos: {
    ruta: 'encuestaSocioEconomica.gastosMicroEmpresa.otrosGastos',
    label: 'Otros gastos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  alimentacion: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.alimentacion',
    label: 'Alimentación',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  arriendoCliente: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.arriendo',
    label: 'Arriendo',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  serviciosCliente: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.servicios',
    label: 'Servicios',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  transporteCliente: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.transporte',
    label: 'Transporte',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  salud: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.salud',
    label: 'Salud',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  educacion: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.educacion',
    label: 'Educación',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  deudasTerceros: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.deudasTerceros',
    label: 'Deudas con terceros',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  otrosGastosCliente: {
    ruta: 'encuestaSocioEconomica.gastosDelCliente.otrosGastos',
    label: 'Otros gastos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  efectivoBancos: {
    ruta: 'encuestaSocioEconomica.activo.efectivoBancos',
    label: 'Efectivo y bancos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  cuentasPorCobrar: {
    ruta: 'encuestaSocioEconomica.activo.cuentasPorCobrar',
    label: 'Cuentas por cobrar',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  inventarios: {
    ruta: 'encuestaSocioEconomica.activo.inventarios',
    label: 'Inventarios',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  inmuebles: {
    ruta: 'encuestaSocioEconomica.activo.inmuebles',
    label: 'Inmuebles',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  activosFijos: {
    ruta: 'encuestaSocioEconomica.activo.activosFijos',
    label: 'Activos fijos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  vehiculos: {
    ruta: 'encuestaSocioEconomica.activo.vehiculos',
    label: 'Vehículos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  proveedores: {
    ruta: 'encuestaSocioEconomica.pasivo.proveedores',
    label: 'Proveedores',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  anticipoClientes: {
    ruta: 'encuestaSocioEconomica.pasivo.anticipoClientes',
    label: 'Anticipo de clientes',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  saldoHipoteca: {
    ruta: 'encuestaSocioEconomica.pasivo.saldoHipoteca',
    label: 'Saldo hipoteca',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  deudasTercerosPasivos: {
    ruta: 'encuestaSocioEconomica.pasivo.deudasTerceros',
    label: 'Deudas con terceros o familiares',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  alimentacionCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.alimentacion',
    label: 'Alimentación',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  arriendoCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.arriendo',
    label: 'Arriendo',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  serviciosCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.servicios',
    label: 'Servicios',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  transporteCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.transporte',
    label: 'Transporte',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  saludCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.salud',
    label: 'Salud',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  educacionCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.educacion',
    label: 'Educación',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  deudasTercerosGastosCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.deudasTerceros',
    label: 'Deudas terceros',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  otrosGastosCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.gastosDelCliente.otrosGastos',
    label: 'Otros gastos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  efectivoBancosCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.activo.efectivoBancos',
    label: 'Efectivo y bancos',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  cuentasPorCobrarCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.activo.cuentasPorCobrar',
    label: 'Cuentas por cobrar',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  activosHogarCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.activo.activosHogar',
    label: 'Activos del hogar',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  inmueblesCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.activo.inmuebles',
    label: 'Inmuebles',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  saldoHipotecaCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.pasivo.saldoHipoteca',
    label: 'Saldo hipoteca',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  deudasTercerosCodeudorAsalariado: {
    ruta: 'datosCoodeudorAsalariado.encuestaSocioEconomica.pasivo.deudasTerceros',
    label: 'Deudas con terceros',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.maxLength(9),
      (control: AbstractControl) => {
        return !REGEX.NUMERIC.test(control.value) ? { pattern: 'Por favor ingresa solo números. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  nombreEmpresaLaboral: {
    ruta: 'datosCoodeudorAsalariado.informacionLaboral.nombreEmpresa',
    label: 'Nombre de empresa',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  cargoLaboral: {
    ruta: 'datosCoodeudorAsalariado.informacionLaboral.cargo',
    label: 'Cargo',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  telefonoLaboral: {
    ruta: 'datosCoodeudorAsalariado.informacionLaboral.telefono',
    label: 'Teléfono',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      Validators.minLength(7),
      Validators.maxLength(10),
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  antiguedadLaboral: {
    ruta: 'datosCoodeudorAsalariado.informacionLaboral.antiguedad',
    label: 'Antigüedad',
    tipo: TiposCampos.number,
    validadores: [
      Validators.required,
      (control: AbstractControl) => {
        return !REGEX.CELLPHONE.test(control.value) ? { pattern: 'Por favor ingresa un número válido. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
  barrioLaboral: {
    ruta: 'datosCoodeudorAsalariado.informacionLaboral.barrio',
    label: 'Barrio',
    tipo: TiposCampos.text,
    validadores: [
      Validators.required,
      Validators.maxLength(50),
      (control: AbstractControl) => {
        return !REGEX.ALPHABETICAL.test(control.value) ? { pattern: 'Por favor ingresa solo letras. ' } : null;
      }
    ],
    editable: (res, resEstado) => [EstadoSolicitud.ValidacionAsistente, EstadoSolicitud.ValidacionGerente, EstadoSolicitud.AprobadaDesembolsada].includes(resEstado.estado)
  },
};

export const CamposEditablesDesembolso = {
  
}


/**
 * {
 *   Incompleta = 1,
 *   Cancelada = 5,
 *   Devuelta = 10,
 *   Creada = 20,
 *   Sincronizada = 40,
 *   ValidacionAsistente = 45,
 *   ValidacionGerente = 50,
 *   Comite = 55,
 *   ValidacionIdentidad = 60,
 *   Rechazada = 65,
 *   LegalizandoCredito = 70,
 *   ErrorCreacionCredito = 71,
 *   AsociandoCodeudores = 74,
 *   GenerandoDesembolso = 75,
 *   ErrorAsociarCodeudores = 76,
 *   DesembolsoConfirmado = 80,
 *   DesembolsoFallido = 81,
 *   AsociacionCodeudoresFallida = 82,
 *   AprobadaDesembolsada = 100,
 * }
 */
export const EstadosSolicitudString = {
  1: 'Incompleta',
  5: 'Cancelada',
  7: 'Renovación activa',
  8: 'Renovación (no cumple con políticas)',
  10: 'Devuelta',
  20: 'Creada',
  35: 'Anulada',
  40: 'Sincronizada',
  45: 'Validación',
  50: 'Aprobación',
  55: 'Comité',
  60: 'OTP',
  66: 'Alta de clientes',
  62: 'Alta de clientes',
  63: 'Alta de clientes',
  64: 'Alta de clientes',
  67: 'Alta de clientes',
  65: 'Rechazada',
  70: 'Creando crédito',
  71: 'Registrando crédito',
  73: 'Anulada por core',
  74: 'Verificando codeudores',
  75: 'Verificando desembolso',
  76: 'Registrando codeudores',
  80: 'Desembolso confirmado',
  81: 'Desembolsando',
  82: 'Verificando codeudores',
  90: 'Dispersión',
  95: 'Dispersión',
  100: 'Aprobada',
  120: 'Dispersión',
  200: 'Finalizada'
};


// Tabla 1 – Tipos de Identificación
export const HCTipoIdentificacion = {
  1: 'CC',
  2: 'NIT',
  3: 'PJE',
  4: 'CE',
  5: 'PAS',
  6: 'CD',
  7: 'TI',
  8: 'DNI',
  9: 'PEP',
};

// Tabla 2 – Estado de documento de identificación
export const HCEstadoDocumento = {
  '00': 'Vigente',
  21: 'Cancelada por muerte o fallecido',
  22: 'Cancelada',
  23: 'Cancelada',
  24: 'Cancelada',
  25: 'Cancelada',
  26: 'Cancelada',
  27: 'Cancelada',
  28: 'Cancelada',
  29: 'Cancelada',
  30: 'No expedida',
  31: 'No expedida',
  32: 'No expedida',
  33: 'No expedida',
  34: 'No expedida',
  35: 'No expedida',
  36: 'No expedida',
  37: 'No expedida',
  38: 'No expedida',
  39: 'No expedida',
  40: 'No expedida',
  41: 'No expedida',
  42: 'No expedida',
  43: 'No expedida',
  44: 'No expedida',
  45: 'No expedida',
  46: 'No expedida',
  47: 'No expedida',
  48: 'No expedida',
  49: 'No expedida',
  50: 'No expedida',
  51: 'No expedida',
  52: 'No expedida',
  53: 'No expedida',
  54: 'No expedida',
  55: 'No expedida',
  56: 'No expedida',
  57: 'No expedida',
  58: 'No expedida',
  59: 'No expedida',
  99: 'En trámite',
};

// Tabla 3 – Tipos de Cuenta
export const HCTiposCuentas = {
  AGR: 'Cartera Agroindustrial',
  AHO: 'Cuentas de ahorro y bancarias',
  ALI: 'Cartera de Alimentos',
  APD: 'Almacén por departamentos',
  CAB: 'Cartera Bancaria',
  CAC: 'Cartera Cooperativas de ahorro y crédito',
  CAU: 'Cartera Automotriz',
  CAV: 'Cartera de Ahorro y Vivienda',
  CBM: 'Créditos Bajo Monto',
  CBR: 'Cartera Bancaria Rotativa',
  CCB: 'Cuentas Corrientes Bancarias',
  CCC: 'Cartera de Crédito de Construcción',
  COF: 'Cartera Corporaciones Financieras',
  COM: 'Cartera Computadores',
  CON: 'Créditos de Consumo',
  CSA: 'Carteras Caja de Compensación y Salud',
  CSP: 'Empresas de Servicios Públicos',
  CTC: 'Cartera de Telefonía Celular',
  CTU: 'Cartera Turismo',
  CVE: 'Cartera Vestuario',
  DIC: 'Departamento de Información Comercial',
  EDU: 'Cartera Educación',
  EST: 'Estatal',
  FER: 'Cartera Ferreterías',
  CCF: 'Cartera Compañías de Financiamiento Comercial',
  CCL: 'Cartera de Compañías de Leasing',
  CCS: 'Cartera Compañías de Seguros',
  CDB: 'Corredores de Bolsa',
  CDC: 'Cartera de Comunicaciones',
  CEL: 'Cartera de Electrodomésticos',
  CFE: 'Cartera Fondo de Empleados',
  CFR: 'Cartera Finca Raíz',
  CLB: 'Cartera Editorial',
  CMU: 'Cartera Muebles',
  CMZ: 'Cartera Comercializadoras',
  COC: 'Cartera Otros Créditos',
  FUN: 'Cartera Fundaciones',
  GRM: 'Cartera Gremios',
  IND: 'Cartera Industrial',
  LAB: 'Cartera Laboratorios',
  LBZ: 'Cartera Libranza',
  MCR: 'Cartera Microcrédito',
  SBG: 'Cartera Sobregiro',
  SEG: 'Cartera Seguridad',
  SFI: 'Servicios Financieros',
  TDC: 'Tarjeta de Crédito',
  TRT: 'Cartera Transporte',
};

// Tabla 4 - estados de pago Tarjeta/Cartera
export const HCEstadoPagoTarjetaCartera = {
  '00': {descripcion: 'No disponible', vigente: true},
  '01': {descripcion: 'Al día', vigente: true},
  '02': {descripcion: 'Tarjeta no entregada', vigente: false},
  '03': {descripcion: 'Cancelada por mal manejo', vigente: false},
  '04': {descripcion: 'Tarjeta robada', vigente: false},
  '05': {descripcion: 'Cancelada vol.', vigente: false},
  '06': {descripcion: 'Cancelada MX', vigente: false},
  '07': {descripcion: 'Tarjeta extraviada', vigente: false},
  '08': {descripcion: 'Pago voluntario', vigente: false},
  '09': {descripcion: 'Pago voluntario', vigente: false},
  10: {descripcion: 'Pago voluntario', vigente: false},
  11: {descripcion: 'Pago voluntario', vigente: false},
  12: {descripcion: 'Pago voluntario', vigente: false},
  13: {descripcion: 'Al día Mora 30', vigente: true},
  14: {descripcion: 'Al día Mora 60', vigente: true},
  15: {descripcion: 'Al día Mora 90', vigente: true},
  16: {descripcion: 'Al día Mora 120', vigente: true},
  17: {descripcion: 'Está en mora 30', vigente: true},
  18: {descripcion: 'Está en mora 60', vigente: true},
  19: {descripcion: 'Está en mora 90', vigente: true},
  20: {descripcion: 'Está en mora 120', vigente: true},
  21: {descripcion: 'FM60ESTAM30', vigente: true},
  22: {descripcion: 'FM90ESTAM30', vigente: true},
  23: {descripcion: 'FM90ESTAM60', vigente: true},
  24: {descripcion: 'FM120ESTAM30', vigente: true},
  25: {descripcion: 'FM120ESTAM60', vigente: true},
  26: {descripcion: 'FM120ESTAM90', vigente: true},
  27: {descripcion: 'RM30ESTAM60', vigente: true},
  28: {descripcion: 'RM30ESTAM90', vigente: true},
  29: {descripcion: 'RM30ESTAM120', vigente: true},
  30: {descripcion: 'RM60ESTAM30', vigente: true},
  31: {descripcion: 'RM60ESTAM60', vigente: true},
  32: {descripcion: 'RM60ESTAM90', vigente: true},
  33: {descripcion: 'RM60ESTAM120', vigente: true},
  34: {descripcion: 'RM90ESTAM30', vigente: true},
  35: {descripcion: 'RM90ESTAM60', vigente: true},
  36: {descripcion: 'RM90ESTAM90', vigente: true},
  37: {descripcion: 'RM90ESTAM120', vigente: true},
  38: {descripcion: 'RM120ESTAM30', vigente: true},
  39: {descripcion: 'RM120ESTAM60', vigente: true},
  40: {descripcion: 'RM120ESTAM90', vigente: true},
  41: {descripcion: 'RM 120 ESTA M 120', vigente: true},
  45: {descripcion: 'Cartera castigada', vigente: true},
  46: {descripcion: 'Cartera recuperada', vigente: false},
  47: {descripcion: 'Dudoso recaudo', vigente: true},
  49: {descripcion: 'Tarjeta renovada', vigente: false},
  60: {descripcion: 'En reclamación', vigente: true},
};

// Tabla 6 - Codigos de garante
export  const HCCodigosGarante = {
  '00': 'Deudor Principal',
  '01': 'Codeudor',
  '02': 'Codeudor',
  '03': 'Codeudor',
  '04': 'Avalista',
  '05': 'Deudor solidario',
  '06': 'Coarrendatario',
  '07': 'Otros Garantes',
  '08': 'Fiador',
  '09': 'No Aplica',
  96: 'Cotitular',
  97: 'Comunal (Solo para cuentas Microcredito – MCR)',
  98: 'No Aplica',
  99: 'No Aplica',
};

export const HCEstadoCivilGenero = {
  1: 'Casada',
  2: 'Viuda',
  3: 'Mujer',
  4: 'Hombre',
};

export const HCSector = {
  1: 'Financiero',
  2: 'Cooperativo',
  3: 'Real',
  4: 'Telecomunicaciones',
};

// Tabla 11 – Tabla de garantías, hábito de pago obligaciones vigentes
export const HCGarantiaHabitoPagoObligacionesVigentes = {
  0: 'SIN GAR',
  1: 'ADMIS',
  2: 'OTR GAR',
  A: 'NO IDON',
  B: 'BIEN RAICES',
  C: 'OTR PREND',
  D: 'PIGN RENTA',
  E: 'GAR SOBER NACION',
  F: 'CONT IRREV FIDUC',
  G: 'FNG',
  H: 'CARTA CRÉD',
  I: 'FAG',
  J: 'PERSONAL',
  K: 'BIEN LEASI NO INMOB',
  L: 'BIEN LEASI INMOB',
  M: 'PRENDA TITULO',
  N: 'DEPOSITOS',
  O: 'SEG CREDITO',
  P: 'OTRAS GARANTÍAS IDÓNEAS',
  Q: 'NO IDÓNEA',
};

// tabla 14
export const HCCalificacionTCAndCC = {
  0: 'No reporta informacion',
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'AA',
  7: 'BB',
  8: 'CC',
  9: 'K',
  '-': 'No reporta informacion',

};

// Tabla 16 – Estados de Cuentas corrientes y de ahorros
export const HCEstadoCuentaCcANDCa = {
  '01': {descripcion: 'Activa', vigente: true},
  '02': {descripcion: 'Cancelada por mal manejo', vigente: false},
  '05': {descripcion: 'Saldada', vigente: false},
  '06': {descripcion: 'Embargada', vigente: true},
  '07': {descripcion: 'Embargada-Activa', vigente: true},
  '09': {descripcion: 'Inactiva', vigente: false},
};

// Tabla 29 - situacion del titular
export  const HCSituacionTitular = {
  0: 'Normal',
  1: 'Concordato',
  2: 'Liquidación Forzosa',
  3: 'Liquidación Voluntaria',
  4: 'Proceso de Reorganización',
  5: 'Ley 550',
  6: 'Ley 1116',
  7: 'Otra',
  8: 'Liquidación Patrimonial',
};

// tabla 35
export const HCCalificacionCAAndCC = {
  0: '-',
  1: 'A',
  2: 'B',
  3: 'C',
};

// Tabla 36 – Clase de Cuenta de Ahorros y Corriente
export const HCClaseCuentaAC = {
  0: 'NOR',
  1: 'NOM',
  2: 'GMF',
  3: 'NGM',
  4: 'ELE',
  5: 'AFC/ACF',
};

// Tabla 40
export  const HCClaseTC = {
  '': 'No reportado',
  0: 'No reportado',
  1: 'Clásica',
  2: 'Gold',
  3: 'Platinum',
  4: 'Otra',
};

// Tabla 43 – Estado de la Cuenta
export const HCEstadoCuentaTCandCC = {
  '00': {descripcion: 'Entidad no reportó', vigente: true},
  '01': {descripcion: 'Al día',  vigente: true},
  '02': {descripcion: 'En Mora',  vigente: true},
  '03': {descripcion: 'Pago Total', vigente: true},
  '04': {descripcion: 'Pago Judicial', vigente: true},
  '05': {descripcion: 'Dudoso Recaudo', vigente: true},
  '06': {descripcion: 'Castigada', vigente: true},
  '07': {descripcion: 'Dación en Pago', vigente: true},
  '08': {descripcion: 'Cancelada Voluntariamente', vigente: false},
  '09': {descripcion: 'Cancelada por mal manejo', vigente: false},
  10: {descripcion: 'Prescripción', vigente: false},
  11: {descripcion: 'Cancelada por la entidad', vigente: false},
  12: {descripcion: 'Cancelada por reestructuración/refinanciación', vigente: false},
  13: {descripcion: 'Cancelada por venta', vigente: false},
  14: {descripcion: 'Insoluta', vigente: false},
  15: {descripcion: 'Cancelada por siniestro', vigente: false},
  16: {descripcion: 'Cancelada por Liquidación Patrimonial.', vigente: false},
};


export const CALIFICACIONES_RENOVACION = [
  'A',
  'AA',
  'AAA',
  'B',
];

export const TipoDestinoPrestamo = [
  {id: 1, name: 'Activo fijo'},
  {id: 2, name: 'Capital de trabajo'},
  {id: 3, name: 'Agricola'},
  {id: 4, name: 'Mejora de la calidad de vida de la familia'}
];

export const nombreCompletoMiembro: (cliente: Cliente) => string = ({datosPersonalesCliente}: Cliente): string => {
  const {nombre1, nombre2, apellido1, apellido2} = datosPersonalesCliente || {};
  return `${nombre1 || ''} ${nombre2 || ''} ${apellido1 || ''} ${apellido2 || ''}`;
};

export enum EstadoTransferencia {
  SolicitudRegistrada = 0,
  ProcesandoTransferencia = 1,
  TransferenciaFinalizada = 2,
  ListaTranferencia = 3,
}
